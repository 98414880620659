import React, { useEffect } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useInView } from 'react-intersection-observer';
import Navbar from '../../components/shared/Navbar';
import Footer from '../../components/shared/Footer';
import './index.css';

export default function Programa() {
  const { ref: ref1, inView: inView1 } = useInView({ threshold: 0 });
  const { ref: ref3, inView: inView3 } = useInView({ threshold: 0 });
  const { ref: ref5, inView: inView5 } = useInView({ threshold: 0 });
  const { ref: ref2, inView: inView2 } = useInView({ threshold: 0 });
  const { ref: ref4, inView: inView4 } = useInView({ threshold: 0 });

  const toggleAnimation = (inView, ...classNames) => {
    classNames.forEach((className) => {
      const elements = document.querySelectorAll(className);

      if (!className.includes('inverse')) {
        elements.forEach((element) => {
          element.classList.toggle('animate-visible', inView);
        });
      } else {
        elements.forEach((element) => {
          element.classList.toggle('animate-visible-inverse', inView);
        });
      }
    });
  };

  useEffect(() => {
    toggleAnimation(inView1, '.partners-scrolling-animate1', '.partners-scrolling-animate-main1', '.partners-scrolling-inverse-animate1');
  }, [inView1]);

  useEffect(() => {
    toggleAnimation(inView3, '.partners-scrolling-animate3', '.partners-scrolling-animate-main3', '.partners-scrolling-inverse-animate3');
  }, [inView3]);

  useEffect(() => {
    toggleAnimation(inView5, '.partners-scrolling-animate5', '.partners-scrolling-animate-main5', '.partners-scrolling-inverse-animate5');
  }, [inView5]);

  useEffect(() => {
    toggleAnimation(inView2, '.partners-scrolling-animate2', '.partners-scrolling-animate-main2', '.partners-scrolling-inverse-animate2');
  }, [inView2]);

  useEffect(() => {
    toggleAnimation(inView4, '.partners-scrolling-animate4', '.partners-scrolling-animate-main4', '.partners-scrolling-inverse-animate4');
  }, [inView4]);

  return (
    <>
      <Navbar page={4} />
      <div className="pageProgram">
        <p className="titulo">PROGRAMA</p>
        <p className="subtitulo">Conhece o evento que espera por ti</p>
        <p className="subtitulo">13 de março de 2024</p>
      </div>
      <div className="vl" />
      <div className="bola bola1">
        <div>
          <p className="nome3 partners-scrolling-animate1">Flash Interviews</p>
          <p className="subnome partners-scrolling-animate1">with a twist</p>
          <p className="hora partners-scrolling-inverse-animate1">10:00</p>
          <p className="textoP partners-scrolling-animate-main1" ref={ref1}>
            As Flash Interviews
            <em>
              {' with a twist '}
            </em>
            são sessões de recrutamento one on one inovadoras e exclusivas com as empresas Diamond!
            Neste momento, os participantes terão a oportunidade de ter uma entrevista de curta duração com as empresas, onde poderão mostrar as
            suas competências e expandir a sua rede de contactos. Ideal para quem procura uma oportunidade de emprego ou de  estágio,
            as Flash Interviews promovem um contacto mais próximo com as empresas.
          </p>
        </div>
      </div>
      <div className="bola bola2">
        <p className="nome2 partners-scrolling-inverse-animate2">Pitching in Diamond</p>
        <p className="hora2 partners-scrolling-animate2">14:30</p>
        <p className="textoP2 partners-scrolling-inverse-animate2" ref={ref2}>
          O Pitching In Diamond, novidade nesta edição do ADATO, será um momento de discussão sobre um conjunto de temas centrais
          no mercado de trabalho. As empresas Diamond terão a oportunidade de se posicionar sobre os variados temas e, ainda,
          responder a algumas questões dos estudantes.
        </p>
      </div>
      <div className="bola bola3">
        <p className="nome partners-scrolling-animate3">Pitches das Empresas</p>
        <p className="hora partners-scrolling-inverse-animate3">15:00</p>
        <p className="textoP partners-scrolling-animate-main3" ref={ref3}>
          Nos pitches das empresas, os vários representantes vão fazer uma breve apresentação da empresa e das suas oportunidades.
          Assim, os participantes poderão ter uma visão geral de cada empresa e do que estas procuram.
        </p>
      </div>
      <div className="bola bola4">
        <p className="nome2 partners-scrolling-inverse-animate4">Networking informal</p>
        <p className="hora2 partners-scrolling-animate4">16:00</p>
        <p className="textoP2 partners-scrolling-inverse-animate4" ref={ref4}>
          No Networking informal, os participantes terão a oportunidade de estabelecer contactos com as empresas num ambiente descontraído, Sem
          Gravatas, Sem Bancas e Sem Complicações. Este momento, permite uma aproximação entre os estudantes
          e o tecido empresarial, e a troca de ideias
          e dicas entre as várias com os representantes. Por fim, associado ao momento informal cada participante terá direito a um copo de gin.
        </p>
      </div>
      <div className="bola bola5">
        <p className="nome partners-scrolling-animate-main5" ref={ref5}>Sessão de Encerramento</p>
        <p className="hora partners-scrolling-inverse-animate5">19:00</p>
      </div>
      <div className="myFooter">
        <Footer />
      </div>
    </>
  );
}
