import React, { useState, useRef } from 'react';
import Card from './Card/index';
import leftArrow from '../../assets/images/misc/left arrow.png';
import rightArrow from '../../assets/images/misc/right arrow.png';

import './index.css';

export default function Carousel() {
  const [middleCard, setMiddleCard] = useState(0);
  const lastArrowClick = useRef(Date.now());

  const getClassPosition = (cardNo) => {
    if (cardNo === middleCard) return 'carousel-card-middle';
    if (cardNo === (middleCard - 1 + 7) % 7) return 'carousel-card-left';
    if (cardNo === (middleCard - 2 + 7) % 7) return 'carousel-card-left-out';
    if (cardNo === (middleCard + 1) % 7) return 'carousel-card-right';
    if (cardNo === (middleCard + 2) % 7) return 'carousel-card-right-in';
    return 'carousel-card-out';
  };

  const canArrowClick = () => {
    const canClick = (Date.now() - lastArrowClick.current > 1000);
    if (canClick) lastArrowClick.current = Date.now();
    return canClick;
  };

  const showPreviousCard = () => {
    if (!canArrowClick()) return;
    setMiddleCard((prevMiddleCard) => (prevMiddleCard - 1 + 7) % 7);
  };

  const showNextCard = () => {
    if (!canArrowClick()) return;
    setMiddleCard((prevMiddleCard) => (prevMiddleCard + 1) % 7);
  };

  const mobileCardClicking = (xMouse) => {
    if (typeof window === 'undefined') return;
    if (window.screen.width < 1024) {
      if (xMouse > (window.screen.width) / 2) showNextCard();
      else showPreviousCard();
    }
  };

  return (
    <>
      <div className="carousel-title-container">
        <div className="carousel-title-font">
          TESTEMUNHOS
        </div>
      </div>
      <div className="carousel" onClick={(e) => mobileCardClicking(e.clientX)} onKeyDown={() => {}} role="button" tabIndex={-1}>
        <Card
          className={getClassPosition(0)}
          author="IT Sector"
          comment="Tivemos a possibilidade de participar no ADATO pela primeira vez em 2021 e sem dúvida
                   que foi um evento que se destacou pela sua originalidade e dinamismo, pela excelente
                   organização e acompanhamento de todos os membros da JuniFEUP.
                   Aguardamos com muito entusiasmo pela edição deste ano!"
        />
        <Card
          className={getClassPosition(1)}
          author="KEO"
          comment="“Foi um prazer estar presente neste evento, tão bem organizado pela JuniFEUP. Foi muito
                    interessante dar a conhecer a KEO à comunidade estudantil, que nos recebeu com muito
                    entusiasmo!”"
        />
        <Card
          className={getClassPosition(2)}
          author="Inova-Ria"
          comment="“A Inova-Ria agradece à Organização do ADATO e a todos os participantes. É uma excelente
                    oportunidade para nos conhecerem melhor num ambiente informal, estabelecer contactos e
                    descobrir falar sobre as nossas ofertas para tese de dissertação de Mestrado ou mesmo um
                    estágio no âmbito do programa GENIUS e outros.”"
        />
        <Card
          className={getClassPosition(3)}
          author="AddVolt"
          comment="“Gostamos muito de conhecer tantos estudantes ambiciosos e motivados, com muita vontade de
                    conhecer o mercado de trabalho e empresas jovens e tecnológicas como a Addvolt.”"
        />
        <Card
          className={getClassPosition(4)}
          author="Advantek"
          comment="“A Advantek Group felicita toda a equipa organizadora do ADATO 2023, pois consideramos ter
                    sido muito competente ao longo de todo o evento. As apresentações foram bem coordenadas e
                    a descontração do cocktail permitiu um networking fácil e eficaz com os interessados
                    estudantes da FEUP. Será uma participação a manter sem margem de dúvida. Parabéns pelo excelente
                    trabalho!”"
        />
        <Card
          className={getClassPosition(5)}
          author="BorgWarner"
          comment="“ADATO é um evento super interessantes onde é possível criar boas sinergias entre empresas e a
                  comunidade estudantil, num ambiente bastante agradável. Acredito que é uma boa forma de os
                  estudantes exporem as suas dúvidas e darem-se a conhecer às empresas, que por sua parte também
                  conseguem transmitir de uma forma mais eficaz os seus propósitos.”"
        />
        <Card
          className={getClassPosition(6)}
          author={'Efficio\u00A0Consulting'}
          comment="“O ADATO foi um dia de grande relevo para a interação dos alunos com as empresas de vários
                    setores. O GEG agradece a toda a organização do evento, pela disponibilidade de estarmos
                    presentes e assim podermos falar das oportunidades que existem numa empresa de projetos
                    e consultoria de engenharia, na construção; e aos alunos, pela forma interessada e ativa
                    que nos receberam.”"
        />
        <div className="carousel-control-arrow carousel-control-arrow-left">
          <button type="button" onClick={() => showPreviousCard()} aria-label="Previous Card">
            <img src={leftArrow} alt="" draggable="false" />
          </button>
        </div>
        <div className="carousel-control-arrow carousel-control-arrow-right">
          <button type="button" onClick={() => showNextCard()} aria-label="Previous Card">
            <img src={rightArrow} alt="" draggable="false" />
          </button>
        </div>
      </div>
    </>
  );
}
