/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import logo from '../../../assets/images/logo/juni-logo-gray.svg';
import slashes from '../../../assets/images/misc/three-slashes.png';
import close from '../../../assets/images/misc/close-icon.png';

import './index.css';

const bodyElement = document.body;
const htmlElement = document.documentElement;

export default function Navbar({ page }) {
  /**
   * Home -> page = 1; Empresas -> page = 2; Oportunidades -> page = 3;
   * Programa -> page = 4; FAQ -> page = 5; Inscreve-te -> page = 6; Contactos -> page = 7;
   */
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  const changeMobileMenuVisibility = () => {
    if (!mobileMenuVisible) {
      bodyElement.classList.add('forbid-overflow-menu-open');
      htmlElement.classList.add('forbid-overflow-menu-open');
      setMobileMenuVisible(true);
    } else {
      bodyElement.classList.remove('forbid-overflow-menu-open');
      htmlElement.classList.remove('forbid-overflow-menu-open');
      setMobileMenuVisible(false);
    }
  };

  useEffect(() => {
    if (window.location.pathname.includes('/partners')) {
      const navbar = document.querySelector('.navbar');
      navbar.classList.add('navbar-partners');
    }
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 820);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth <= 820);
    });
  }, []);

  const bodyStyles = page === 2 ? { overflow: 'hidden', backgroundColor: '#f2f0ed', overflowX: 'hidden' } : {};

  return (
    <body style={isMobile ? bodyStyles : {}}>
      <div className="navbar-outer-space">
        <div className="navbar">
          <div>
            <a href="/">
              <img alt="JuniFeup logo" src={logo} className="navbar-logo-resize" />
            </a>
          </div>
          {/* PC */}
          <div className="navbar-sections navbar-sections-pc">
            <a href="empresas" className="navbar-sections-font">
              <div>EMPRESAS</div>
            </a>
            <a href="oportunidades" className="navbar-sections-font">
              <div>OPORTUNIDADES</div>
            </a>
            <a href="program" className="navbar-sections-font">
              <div>PROGRAMA</div>
            </a>
            <a href="faq" className="navbar-sections-font">
              <div>FAQ</div>
            </a>
            <a href="inscrição" className="navbar-sections-font">
              <div>INSCREVE-TE</div>
            </a>
            <a href="contactos" className="navbar-sections-font">
              <div>CONTACTOS</div>
            </a>
          </div>
          {/* MOBILE */}
          <div className="navbar-sections navbar-sections-mobile">
            <button type="button" aria-label="Open Menu" onClick={() => changeMobileMenuVisibility()}>
              <img alt="" src={slashes} className="navbar-slashes-resize" />
            </button>
          </div>
        </div>
      </div>
      {/* MOBILE MENU */}
      <div className={`navbar-mobile-menu ${mobileMenuVisible ? 'navbar-mobile-menu-close' : 'navbar-mobile-menu-open'}`}>
        <div className="navbar-mobile-menu-icon">
          <button type="button" aria-label="Close Menu" onClick={() => changeMobileMenuVisibility()}>
            <img alt="" src={close} className="navbar-mobile-close-icon" />
          </button>
        </div>
        <div className="navbar-mobile-menu-sections navbar-menu-font">
          <a href="/">
            <div className={`navbar-menu-font-hover ${page === 1 ? 'navbar-menu-font-highlight navbar-menu-font-highlight-home' : ''}`}>
              HOME
            </div>
          </a>
          <a href="empresas">
            <div className={`navbar-menu-font-hover ${page === 2 ? 'navbar-menu-font-highlight navbar-menu-font-highlight-empresas' : ''}`}>
              EMPRESAS
            </div>
          </a>
          <a href="oportunidades">
            <div className={`navbar-menu-font-hover ${page === 3 ? 'navbar-menu-font-highlight navbar-menu-font-highlight-oportunidades' : ''}`}>
              OPORTUNIDADES
            </div>
          </a>
          <a href="program">
            <div className={`navbar-menu-font-hover ${page === 4 ? 'navbar-menu-font-highlight navbar-menu-font-highlight-programa' : ''}`}>
              PROGRAMA
            </div>
          </a>
          <a href="faq">
            <div className={`navbar-menu-font-hover ${page === 5 ? 'navbar-menu-font-highlight navbar-menu-font-highlight-faq' : ''}`}>
              FAQ
            </div>
          </a>
          <a href="inscrição">
            <div className={`navbar-menu-font-hover ${page === 6 ? 'navbar-menu-font-highlight navbar-menu-font-highlight-inscreve' : ''}`}>
              INSCREVE&#8209;TE
            </div>
          </a>
          <a href="contactos">
            <div className={`navbar-menu-font-hover ${page === 7 ? 'navbar-menu-font-highlight navbar-menu-font-highlight-contactos' : ''}`}>
              CONTACTOS
            </div>
          </a>
        </div>
      </div>
    </body>
  );
}
