import React, { useState } from 'react';
import './index.css';
import { Plus, Dash } from 'react-bootstrap-icons';
import Navbar from '../../components/shared/Navbar';
import Footer from '../../components/shared/Footer';

export default function Faq() {
  document.title = 'ADATO';
  const resposta4 = 'No ADATO, terás a possibilidade de estabelecer contactos que facilitem a tua entrada no mercado de trabalho. '
  + 'Demarca-te dos milhares de estudantes que são formados todos os anos no ensino superior e vem garantir o teu futuro!';
  const resposta3 = 'O ADATO será no dia 13 de março e vai estar divido em vários momentos diferentes. O evento inicia com as Flash Interviews '
  + 'with a twist às 10h. Durante a tarde seguem-se o Pitching In Diamond e os pitches das empresas.'
  + ' Terminamos o dia com um cocktail de networking com as empresas e os participantes.';
  const resposta2 = 'O evento terá lugar na FEUP, no entanto, as inscrições estão abertas a qualquer estudante universitário! '
  + 'Depois de te inscreveres, receberás todas as informações sobre os diferentes momentos.';
  const resposta = 'O Another Day At The Office consiste num cocktail de networking organizado pela JuniFEUP. Neste evento “sem gravatas, '
  + 'sem bancas, sem complicações”, estarão presentes dezenas de empresas com quem poderás trocar ideias e brindar ao teu sucesso.';

  const faqItems = [
    { id: '01', pergunta: 'O QUE É?', resposta },
    { id: '02', pergunta: 'ONDE?', resposta: resposta2 },
    { id: '03', pergunta: 'QUANDO?', resposta: resposta3 },
    { id: '04', pergunta: 'PORQUE DEVO PARTICIPAR?', resposta: resposta4 },
    { id: '05', pergunta: 'QUAL O DRESS CODE?', resposta: 'Business Casual. O evento promove a descontração num ambiente empresarial.' },
    {
      id: '06',
      pergunta: 'TEM ALGUM CUSTO?',
      resposta: 'A participação no evento é gratuita e ainda terás direito a brindes das empresas participantes!',
    },
  ];

  const [showAnswers, setShowAnswers] = useState(
    faqItems.reduce((acc, item) => {
      acc[item.id] = false;
      return acc;
    }, {}),
  );

  const toggleAnswer = (id) => {
    setShowAnswers((prevShowAnswers) => {
      const newShowAnswers = { ...prevShowAnswers };
      Object.keys(newShowAnswers).forEach((key) => {
        if (key !== id) {
          newShowAnswers[key] = false;
        }
      });
      newShowAnswers[id] = !newShowAnswers[id];
      return newShowAnswers;
    });
  };

  return (
    <>
      <Navbar page={5} />
      <div className="faqs-page">
        <p className="faqs-titulo">{" FAQ's "}</p>
        {faqItems.map((item) => (
          <div className="faq-box" key={item.id}>
            <button
              type="button"
              className="faq"
              onClick={() => toggleAnswer(item.id)}
            >
              <p className="faqs-index">{item.id}</p>
              <p className="faqs-pergunta">{item.pergunta}</p>
              <div className="faqs-simbolo">
                {showAnswers[item.id] ? <Dash /> : <Plus />}
              </div>
            </button>
            <div className="faqs-barra" />
            <div className={`faqs-resposta ${showAnswers[item.id] ? 'show-answer' : ''}`}>
              <p className="faqs-respostaText" id={`faq-answer-${item.id}`}>{item.resposta}</p>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
}
