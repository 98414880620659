/* eslint-disable global-require */
/* eslint-disable quotes */

const partners = {
  '00': {
    description: `A Deloitte é líder global na prestação de serviços de audit & assurance, consulting, financial\
    advisory, risk advisory, tax e serviços relacionados. Com mais de 175 anos de atividade e com o compromisso\
    de fazer a diferença, a nossa organização tem crescido em escala e diversidade - com mais de 450.000\
    profissionais, em mais de 150 países e territórios, prestam estes serviços - e, ainda assim, a nossa cultura\
    partilhada mantém-se a mesma.\n
    Em Portugal, temos escritórios em Lisboa, Porto, Viseu, Faro e Coimbra. Contamos com mais de 5.500 profissionais\
    que assumem diariamente um compromisso com a excelência.`,
    logoImgPath: require('../../assets/images/partners/diamond/1_DELOITTE.svg').default,
    representatives: [
      {
        name: 'Sílvia Carmona',
        imgPath: require('../../assets/images/partners/rep/Deloitte/Sílvia Carmona.jpg'),
        cargo: 'People Attraction',
      },
      {
        name: 'Carolina Santos',
        imgPath: require('../../assets/images/partners/rep/Deloitte/Carolina Santos.jpg'),
        cargo: 'People Attraction',
      },
      {
        name: 'Mariana Salazar',
        imgPath: require('../../assets/images/partners/rep/Deloitte/Mariana Salazar.jpg'),
        cargo: 'People Attraction',
      },
    ],
    interestedAreas: ['Engenharia e Gestão Industrial', "Gestão"],
  },
  '01': { // GEG
    description: `O GEG é uma empresa de referência de Engenharia, com sede em Campanhã, Porto, que desenvolve\
    projetos em Portugal e internacionalmente. Oferecem a integração numa equipa de elevada qualidade técnica,\
    com um excelente espírito de equipa, o que representa uma grande oportunidade de aprender e crescer através\
    da formação contínua e trabalho próximo com engenheiros mais experientes. Garantem a participação em projetos\
    de renome nacional e internacional, aliciantes, de grande variedade e diversidade, numa empresa estável, de\
    confiança, e em crescimento. Apostam na inovação e desenvolvimento contínuo, incluindo a utilização\
    abrangente das novas tecnologias e aprofundamento de soluções que contribuam para um mundo sustentável.`,
    logoImgPath: require('../../assets/images/partners/diamond/2_G.svg').default,
    representatives: [
      {
        name: 'Raquel Campos e Matos',
        imgPath: require('../../assets/images/partners/rep/GEG/Raquel Campos e Matos.jpg'),
        cargo: 'Gestora de Produção e de Projetos',
      },
      {
        name: 'João Lencastre',
        imgPath: require('../../assets/images/partners/rep/GEG/João Lencastre.jpg'),
        cargo: 'COO',
      },
      {
        name: 'Mafalda Santos',
        imgPath: require('../../assets/images/partners/rep/GEG/Mafalda Santos.jpg'),
        cargo: 'Gestora de Projetos',
      },
      {
        name: 'Pedro Nascimento',
        imgPath: require('../../assets/images/partners/rep/GEG/Pedro Nascimento.jpg'),
        cargo: 'Especialista em Sustentabilidade',
      },
    ],
    interestedAreas: [
      'Engenharia civil',
      'Engenharia mecânica',
      'Engenharia eletrotécnica',
      'Engenharia do ambiente',
      'Engenharia e gestão industrial',
    ],
    oportunities: [`Estágios em diversas áreas: vias de comunicação, estruturas e geotecnia, gestão de projetos,
    hidráulica, sustentabilidade, projeto hvac e gestão de empresas (marketing, comercial, processos)`],
  },
  '02': { // IT SECTOR
    description: `A ITSector é uma empresa de desenvolvimento de software especializada na Transformação Digital\
    para instituições financeiras. Desde 2005 que permitimos a utilizadores de todo o mundo interagir com\
    aplicações empresariais, mudando a sua vida através de tecnologia, inovação e conhecimento especializado.\
    Desenvolvemos projetos para mais de 20 países em todo o mundo a partir dos nossos centros de desenvolvimento\
    em Portugal, sendo que estamos localizados no Porto, Lisboa, Aveiro, Braga, Bragança e Castelo Branco.`,
    logoImgPath: require('../../assets/images/partners/diamond/3_IT_SECTOR.png'),
    representatives: [
      {
        name: 'Ana Rodrigues',
        imgPath: require('../../assets/images/partners/rep/ITSECTOR/Ana_Rodrigues.jpg'),
      },
      {
        name: 'Rita Videira',
        imgPath: require('../../assets/images/partners/rep/ITSECTOR/Rita_Videira.jpg'),
      },
    ],
    interestedAreas: ['Engenharia Informática'],
    oportunities: [
      'Estágio Curriculares, de verão',
      'Profissionais/1º emprego (.NET Developer, ReactJS Developer, Android Developer, iOS Developer, Java Developer)',
    ],
  },
  '03': { // KEO
    description: `A KEO é uma empresa internacional de consultoria de engenharia e arquitetura, com 60 anos de\
    história. Presentes nos rankings mundiais de arquitetura e engenharia, e com escritórios abrangendo sete países,\
    conta com o talento de 2500 profissionais de mais de 60 nações. Em Portugal desde 2020, onde conta já com mais\
    de 70 colaboradores, iniciou operações na cidade do Porto, nos seus escritórios em Campanhã. Em 2023, abriu\
    escritórios também em Lisboa, dando seguimento à sua estratégia de crescimento na Europa, e em particular em\
    Portugal. A KEO oferece uma solução totalmente integrada, proporcionando aos seus clientes um conjunto de\
    profissionais comprometidos e experientes, capazes de resolver os mais variados desafios e potenciar as suas oportunidades.\n
    Os serviços da KEO incluem: Vias e Infraestruturas; Engenharia de Edifícios - Estruturas, Instalações e Equipamentos;\\  
    Sustentabilidade e Ambiente; Arquitetura e Design de Interiores; Planeamento Urbano; Arquitetura Paisagista; Gestão de Projeto;\
    Orçamentação e Controlo de Custos.`,
    logoImgPath: require('../../assets/images/partners/diamond/4_KEO.svg').default,
    representatives: [
      {
        name: 'Nuno Santos Silva',
        imgPath: require('../../assets/images/partners/rep/KEO/Nuno_Silva.jpg'),
        cargo: 'Senior Electrical Engineer / team leader da área de Dry Utilities (divisão de Infraestruturas) da KEO Porto',
      },
      {
        name: 'Luísa Santos',
        imgPath: require('../../assets/images/partners/rep/KEO/Luisa_Santos.jpg'),
        cargo: 'Senior Civil Engineer / team lead da área de Estradas/Vias (divisão de Infraestruturas) da KEO Porto',
      },
      {
        name: 'Leonardo Pires',
        imgPath: require('../../assets/images/partners/rep/KEO/Leonardo_Pires.jpg'),
        cargo: 'Graduate Engineer da divisão de infraestruturas (Highways) da KEO Porto',
      },
      {
        name: 'Ana Rita Loza',
        imgPath: require('../../assets/images/partners/rep/KEO/Ana_Rita_Loza.jpg'),
        cargo: 'Senior Project Manager / líder da divisão de Ambiente e Sustentabilidade da KEO Porto.',
      },
    ],
    interestedAreas: ['Engenharia Civil', 'Engenharia do ambiente'],
    oportunities: [
      'Architects and Interior Designers with a flair for innovation',
      'Landscape Architects with a vision for sustainability Urban Planners with a passion for shaping communities',
      'Civil, Structural, and MEP Engineers with a commitment to excellence',
      'Environmental Engineers and Sustainability Consultants with a dedication for preserving our environment',
    ],
  },
  '04': {
    logoImgPath: require('../../assets/images/partners/diamond/5_OUT_SYSTEMS.svg').default,
    description: `OutSystems is a leading provider of low-code application development platforms, empowering\
    organizations to rapidly build and deploy enterprise-grade applications with minimal coding. Founded in 2001,\
    OutSystems offers a visual development environment, extensive integration capabilities, and comprehensive\
    support, enabling businesses to accelerate digital transformation initiatives.`,
    representatives: [
      {
        name: 'Ana Dias',
        imgPath: require('../../assets/images/partners/rep/OUTSYSTEMS/Ana_Dias.jpg'),
        cargo: 'Senior Talent Acquisition Specialist',
      },
      {
        name: 'Carlos Primavera',
        imgPath: require('../../assets/images/partners/rep/OUTSYSTEMS/Carlos_Primavera.jpg'),
        cargo: 'Talent Acquisition Specialist',
      },
      {
        name: 'Diogo Alves',
        imgPath: require('../../assets/images/partners/rep/OUTSYSTEMS/Diogo_Alves.jpg'),
        cargo: 'Principal Engagement Manager',
      },
    ],
    interestedAreas: ['Engenharia Informática'],
  },
  '05': {
    description: `A MC é líder do mercado nacional, no retalho alimentar, com um conjunto de formatos distintos\
    que oferecem uma variada gama de produtos de qualidade superior aos melhores preços: Continente (hipermercados),\
    Continente Modelo e Continente Bom dia (supermercados de conveniência), Meu Super (lojas de proximidade em\
    formato franchising), Bagga (cafetarias e restaurantes), Go Natural ( restaurantes saudáveis), Note!\
    (livraria/papelaria), ZU (produtos e serviços para cães e gatos), Well's (saúde, bem-estar e ótica).\n
    Trabalhamos todos os dias para ser a nossa melhor versão, promovendo um ambiente colaborativo num workplace\
    saudável e inclusivo.\n
    Importam-nos equipas realizadas e desafiadas, inseridas numa cultura que promove a contínua melhoria da forma\
    como trabalhamos e desenvolvemos todos os que estão connosco, impactando, cada vez mais, a comunidade à nossa volta.`,
    logoImgPath: require('../../assets/images/partners/op/61_MC_SONAE.png'),
    representatives: [
      { name: 'Sara Dias', cargo: 'HR Talent Aquisition Manager' },
      { name: 'Braulio Araujo', cargo: 'Trainee' },
    ],
    interestedAreas: ['Procuram estudantes de diversas áreas que sejam curiosos, que queiram conhecer melhor a empresa,'
    + ' que gostem de desafios, de experimentar, de trabalhar em equipa e que os desafiem '],
    oportunities: ['Têm diversas oportunidades para diferentes áreas funcionais'],
  },
  10: { // ACCENTURE
    description: `Accenture is a global professional services company with leading capabilities in digital, cloud\
    and security. Combining unmatched experience and specialized skills across more than 40 industries, we offer\
    Strategy and Consulting, Interactive, Technology and Operations services — all powered by the world's largest\
    network of Advanced Technology and Intelligent Operations centers. Our 735,000 people deliver on the promise\
    of technology and human ingenuity every day, serving clients in more than 120 countries. We embrace the power\
    of change to create value and shared success for our clients, people, shareholders, partners and communities.`,
    logoImgPath: require('../../assets/images/partners/gold/1_ACCENTURE.png'),
    representatives: [
      { name: 'Vera Gomes', cargo: 'Recruiting Analyst' },
      { name: 'Henrique Alves', cargo: 'Security Delivery Manager' },
      { name: 'Ruben Rodrigues', cargo: 'Cloud Security Delivery Associate' },
    ],
    interestedAreas: ['Engenharia Informática'],
    oportunities: ['Programa de graduates na zona de Braga/Porto'],
  },
  11: { // DEUS
    description: `We are the DEUS initiative, and we are all about exploring AI in the service of humanity. No,\
    we are not calling ourselves gods(!), but our name is a reference to ‘deus ex machina’ (or god from the machine\
    in Latin). We believe that artificial intelligence technologies such as machine learning, computer vision and\
    natural language processing will have a massive impact on the way we live, and we want to make sure that we\
    create a positive impact on the world with these AI services.\n
    As a happy coincidence, DEUS is also an acronym representing the quadrivium of key disciplines we believe are\
    important to building successful AI-powered services: Data / Engineering / User-experience design / Strategy.\n
    DEUS is a new initiative, but the founders have been working together very successfully for years, so there is\
    a comfortable family feel to the company. We have an amazing set of international client partnerships\
    (and projects/challenges). Our headquarters is located in the heart of the city centre of Amsterdam at the\
    Brouwersgracht. We also have a team in Portugal that is based out of the beautiful city of Porto with an office\
    close to the beach, and our Spanish team is based out of the charming center of A Coruña!`,
    logoImgPath: require('../../assets/images/partners/gold/2_DEUS.png'),
    representatives: [
      {
        name: 'Patrícia Barbosa',
        imgPath: require('../../assets/images/partners/rep/DEUS/Patricia_Barbosa.png'),
        cargo: 'People and culture',
      },
      {
        name: 'Liliana Antão',
        imgPath: require('../../assets/images/partners/rep/DEUS/Liliana_Antao.png'),
        cargo: 'Senior data scientist',
      },
      {
        name: 'Eduardo Carvalho',
        imgPath: require('../../assets/images/partners/rep/DEUS/Eduardo_Carvalho.png'),
        cargo: 'Backeng Engineer',
      },
    ],
    interestedAreas: ['Engenharia informática', 'Inteligência artificial e ciência de dados'],
    oportunities: ['Data', 'Engineering', 'Design'],
  },
  12: {
    description: `Headquartered in Denver Colorado, DSR Corporation is a cutting-edge software products and services\
    company that serves companies around the globe. With over two decades of experience, DSR possesses rich and proven\
    expertise in IoT, big data and analytics, enterprise web system development, wireless, blockchain, embedded system\
    development, M2M, UX/UI, computer vision, and mobile development.\n
    Our clients include such companies as Bosch, Casio, Netflix, Amazon, Panasonic, and others!\n
    The company has offices located in different countries across the globe, spread over 3 continents.`,
    logoImgPath: require('../../assets/images/partners/gold/3_DSR.png'),
    representatives: [
      { name: 'Gil Pinheiro', cargo: 'Machine Learning and Computer Vision Engineer' },
      { name: 'Inês Santos', cargo: 'IT Recruiter' },
      { name: 'Sílvia Rocha', cargo: 'Backend developer' },
    ],
    interestedAreas: ['Engenharia Informática', 'Engenharia Eletrotécnica'],
    oportunities: [
      'Posições na empresa: backend, frontend, embedded, Quality Assurance, Business Analyst, Project Manager e Computer Vision.',
      'As oportunidades abertas a recém graduados variam consoante o período do ano e destinam-se sobretudo a '
      + 'estudantes das áreas de engenharia informática e eletrotécnica.',
    ],
  },
  13: {
    description: `A EY é uma organização global, presente em mais de 150 países com mais de 395.000 profissionais\
    que partilham os mesmos valores e objetivos, operando segundo os mais rigorosos padrões de excelência profissional\
    e integridade. Com uma oferta integrada de serviços, a EY trabalha de acordo com um modelo operacional\
    verdadeiramente integrado, que utiliza em todo o mundo os mesmos procedimentos e ferramentas tecnológicas,\
    assegurando consistência e qualidade no serviço ao cliente. Norteada pelo propósito empresarial “Building a Better\
    Working World”, a EY tem um papel importante na construção de um melhor mundo de negócios, quer junto dos seus\
    colaboradores, clientes ou comunidades onde se insere. Em Portugal, a EY tem uma estrutura composta por\
    aproximadamente 1.700 pessoas e todos os anos recruta um elevado número de jovens graduados e viabiliza a sua\
    carreira. Para tal, procuramos pessoas que demonstram integridade, espírito de equipa, coragem para liderar e que\
    se encontrem motivadas para trabalhar num ambiente internacional.`,
    logoImgPath: require('../../assets/images/partners/gold/4_EY.png'),
    representatives: [
      { name: 'Andreia Reis', cargo: 'Head of Talent Attraction and Acquisition' },
      { name: 'Beatriz Caeiro', cargo: 'Talent Attraction and Acquisition' },
    ],
    interestedAreas: ['Engenharia e Gestão Industrial', 'Engenharia Informática'],
    oportunities: ['New EYers, um programa que procura integrar na empresa recém-licenciados ou mestres ou finalistas'
    + ' de mestrado que procurem iniciar o seu percurso profissional'],
  },
  14: { // GLINTT
    description: `Global nas tecnologias, Global nas geografias, Global nas indústrias.\
    Com mais de 30 anos de experiência e 1200 mentes voltadas para o futuro, a Glintt, agora uma empresa Global,\
    abraça o mundo através da tecnologia. Concentrada em proporcionar estabilidade numa sociedade em constante\
    mudança através da Glintt Life, líder ibérica em HealthTech e da Glintt Next, consultora tecnológica\
    multissectorial. Porque num mundo que muda mais rápido que o tempo, reinventamo-nos para criar\
    valor capaz de superar o amanhã.\n
    Somos a Glintt Global. Uma empresa líder em tecnologia Glintt Global.`,
    logoImgPath: require('../../assets/images/partners/op/51_GLINTT.png'),
    representatives: [
      {
        name: 'Juliana Mota',
        imgPath: require('../../assets/images/partners/rep/GLINTT/Juliana_Mota.jpg'),
        cargo: 'Senior Tech Talent Acquisition da equipa de People',
      },
      {
        name: 'Catarina Faria',
        imgPath: require('../../assets/images/partners/rep/GLINTT/Catarina_Faria.jpg'),
        cargo: 'Tech Talent Aquisition',
      },
      {
        name: 'Diogo Nunes',
        imgPath: require('../../assets/images/partners/rep/GLINTT/Diogo_Nunes.jpg'),
        cargo: 'Junior Developer da Glintt Life - Hospitals',
      },
    ],
    interestedAreas: ['Engenharia Informática', 'Engenharia Eletrotécnica', 'Engenharia Biomédica', 'Engenharia e Gestão Industrial'],
    oportunities: ['Academia Glintt (Programa de Estágios Profissionais)', 'Programa de Estágios de Verão'],
  },
  15: { // Lactogal
    description: `Há mais de duas décadas que a @Lactogal se assume como uma empresa de pessoas para pessoas, reconhecida\
    por produzir alimentos com os mais altos padrões de qualidade, capazes de responder às necessidades das pessoas, promover\
    o seu bem-estar e ajudá-las nas suas vidas.\n
    É do trabalho empenhado de toda a família Lactogal que nascem produtos, queridos por todos, de marcas como Mimosa, Agros,\
    Matinal, Gresso, Vigor, Pleno, Castelões, Milhafre dos Açores, Primor, Serra Dourada, Castelinhos, Serra da Penha e Fresky.\n
    Com 9 fábricas na Península Ibérica, somos centenas e centenas de pessoas a dar o seu melhor para que todos possam ter,\
    sempre, o melhor à sua mesa.\n
    Definimo-nos como uma empresa que trabalhamos para o bem-estar através da alimentação.`,
    logoImgPath: require('../../assets/images/partners/gold/6_LACTOGAL.png'),
    representatives: [
      {
        name: 'Sandra Gomes',
        cargo: 'Coordenadora de Desenvolvimento de Pessoas',
        imgPath: require('../../assets/images/partners/rep/Lactogal/Sandra Gomes.png'),
      },
      {
        name: 'Teresa Ferreira',
        cargo: 'Técnica de Desenvolvimento RH',
        imgPath: require('../../assets/images/partners/rep/Lactogal/Teresa Ferreira.jpg'),
      },
    ],
    interestedAreas: ['Engenharia Química', 'Bioengenharia', 'Engenharia Informática', 'Engenharia Eletrotécnica', 'Engenharia Mecânica'],
    oportunities: ['Equipa de Sistemas de Informação'],
  },
  16: { // NOS
    description: `A NOS é uma empresa orientada para o futuro, comprometida com a excelência e a satisfação dos clientes.\n
    Oferecemos soluções fixas e móveis de última geração, televisão, internet, voz e dados para todos os segmentos de mercado,\
    e somos líderes na distribuição e exibição cinematográficas.\n
    Acreditamos no poder da tecnologia para combater os desafios ambientais e sociais que enfrentamos e, porque somos parte de\
    um todo, temos como objetivo último contribuir com as melhores soluções para o desenvolvimento sustentável.\n
    Queremos ser o motor da transformação de Portugal, participando ativamente na construção de uma sociedade melhor, em que\
    tudo e toda as pessoas estão ligadas, entre si e ao mundo, de formas extraordinárias.`,
    logoImgPath: require('../../assets/images/partners/op/71_NOS.png'),
    representatives: [
      { name: 'Rafaela Melo', cargo: 'Young Talent Programs Manager' },
      { name: 'Pedro Dória', cargo: 'Head of Mobile Device Certification and Mobile Offer' },
      { name: 'David Castro', cargo: 'Project Manager' },
    ],
    interestedAreas: ['Gestão e STEM (Ciência, tecnologia, engenharia e matemática)'],
    oportunities: ['Programa de Trainees NOS Alfa'],
  },
  17: { // SIEMENS
    description: `A Siemens é uma empresa tecnológica focada na indústria, infraestruturas, transportes e saúde.\
    Criamos tecnologias com propósito para benefício dos nossos clientes - sejam fábricas com maior eficiência\
    de recursos, cadeias de aprovisionamento resilientes, edifícios e redes mais inteligentes, transportes mais\
    sustentáveis e confortáveis, ou cuidados de saúde mais avançados. Ao combinar os mundos real e digital, a\
    Siemens ajuda a transformar o dia-a-dia de milhares de milhões de pessoas. A Siemens em Portugal tem hoje\
    mais de 3000 colaboradores de 60 nacionalidades diferentes e está presente em 3 localidades: Alfragide,\
    Freixieiro e Corroios.`,
    logoImgPath: require('../../assets/images/partners/gold/8_SIEMENS.png'),
    representatives: [{
      name: 'Sónia Palma',
      cargo: 'Partner Management / SCE Promotor',
      imgPath: require('../../assets/images/partners/rep/SIEMENS/Sonia_Palma.jpg'),
    }],
    interestedAreas: ['Engenharia Informática', 'Engenharia Eletrotécnica', 'Engenharia Mecânica'],
  },
  18: { // SISINT
    description: `A Sisint é uma empresa portuguesa, constituída em 2003, que tem como objetivo o fornecimento\
    de serviços de engenharia, projeto e consultoria para os sectores da energia, transportes e instalações\
    técnicas de grandes edifícios. Dispondo de um grupo de profissionais de engenharia altamente especializados,\
    realizamos uma gama alargada de serviços e fornecimentos no domínio do projeto, comissionamento e colocações\
    em serviço de instalações de produção, transporte e distribuição de energia.\n
    Com sede em Portugal, a SISINT tem expressão em mercados internacionais, nomeadamente na Europa, no Extremo\
    Oriente (Macau), em África (Moçambique) e na América do Sul (Brasil).\n
    Somos uma empresa certificada Qualidade, Ambiente e Segurança, segundo as normas NP EN ISSO 9001/14001 e OHSAS 18001.`,
    logoImgPath: require('../../assets/images/partners/gold/90_sig.png'),
    representatives: [
      {
        name: 'João Pedro Riboira',
        cargo: 'Engenheiro de Projeto, Departamento de Automação e Controlo',
        imgPath: require('../../assets/images/partners/rep/SISINT/Joao_Morais_de_Castro.png'),
      },
      {
        name: 'João Morais de Castro',
        cargo: 'Gestor de Projetos de Energias Renováveis, Departamento de Automação e Controlo',
        imgPath: require('../../assets/images/partners/rep/SISINT/Joao_Pedro_Riboira.png'),
      },
      {
        name: 'Paula Julieta Gonçalves',
        cargo: 'Responsável de Recursos Humanos',
        imgPath: require('../../assets/images/partners/rep/SISINT/Paula_Julieta_Goncalves.png'),
      },
    ],
    interestedAreas: ['Engenharia Eletrotécnica (Foco principal)',
      'Engenharia Civil',
      'Engenharia e Gestão Industrial',
      'Engenharia Informática', 'Engenharia Mecânica'],
    oportunities: ['Estágios profissionais (ao abrigo da medida ATIVAR), curriculares ou de Verão', 'Candidaturas expontâneas'],
  },
  19: { // VEOLIA
    description: `Líder mundial em serviços ambientais, a Veolia responde aos desafios das alterações climáticas\
    e da preservação dos recursos em 44 países nos cinco continentes, contando com o envolvimento ativo de 220.000\
    colaboradores. Com 170 anos de experiência nas áreas da água, energia e resíduos, a Veolia concebe e implementa\
    soluções inovadoras de descarbonização, de despoluição e de valorização dos recursos, para apoiar as cidades e\
    as empresas na sua transformação ecológica. Em Portugal, a Veolia está presente desde 1992, atuando nas áreas\
    da gestão da água, dos resíduos e da energia com soluções adaptadas às necessidades dos seus clientes: indústrias,\
    cidades e setor terciário especializado.`,
    logoImgPath: require('../../assets/images/partners/gold/91_VEOLIA.png'),
    representatives: [
      { name: 'Beatriz Avelino', cargo: 'Recursos Humanos', imgPath: require('../../assets/images/partners/rep/Veolia/Beatriz Avelino.jpg') },
      { name: 'Madalena Gomes', cargo: 'Marketing Strategist', imgPath: require('../../assets/images/partners/rep/Veolia/Madalena Gomes.png') },
      { name: 'João Venâncio', imgPath: require('../../assets/images/partners/rep/Veolia/João Venâncio.jpg') },
    ],
    interestedAreas: ['Engenharia do Ambiente', 'Engenharia Mecânica', 'Engenharia Eletrotécnica', 'Engenharia Química'],
    oportunities:
      [
        'Vagas em aberto para Engenheiro(a) Júnior para Direção de Obra',
        'Engenheiro(a) de Estudos e Projetos',
        'Engenheiro(a) Electrotécnico Júnior - Zona Oeste',
        'Engenheiro(a) Júnior - Gestão de Manutenção',
        'Engenheiro(a) Júnior de Direção de Obra - Lisboa',
      ],
  },
  20: { // Advantek
    logoImgPath: require('../../assets/images/partners/silver/1_ADVANTEK.svg').default,
    description: `A passionate, opportunity-driven, and innovative technology company focusing on creating engineering\
    and digital solutions for our customers.`,
    representatives: [
      {
        name: 'José Alexandre Silva',
        imgPath: require('../../assets/images/partners/rep/ADVANTEK/Jose_Alexandre_Silva.jpg'),
        cargo: 'HR Partner & Talent Acquisition',
      },
      {
        name: 'Andreia Pereira',
        imgPath: require('../../assets/images/partners/rep/ADVANTEK/Andreia_Pereira.jpg'),
        cargo: 'Head of Digital',
      },
    ],
    interestedAreas: ['Engenharia Informática'],
    oportunities: [
      'Data Engineer',
      'Commissioning / Automotive Engeneering (Newly Educated / Junior Profile)',
      'Embedded Software Engineer',
      'Software Engineer'],
  },
  21: { // Casais
    description: `O Grupo Casais é, atualmente, um dos maiores do setor da construção em Portugal, com uma grande\
    diversidade de negócios, afirmando-se em áreas tão distintas como a Engenharia, Comércio, Indústria, Turismo,\
    Imobiliária, Ambiente, Energia e Serviços, apresentando soluções “chave-na-mão” adequadas às expetativas dos\
    clientes e parceiros de negócio. Ao longo de mais de cinco décadas de atividade da Casais - Engenharia e\
    Construção, o investimento em torno de uma gestão cada vez mais eficaz e eficiente, no conhecimento do mercado\
    e do negócio, a confiança da rede de clientes, fornecedores e parceiros e a aposta na especialização dos\
    recursos humanos potenciaram a diversificação da atividade e dos serviços prestados. A Área Internacional é um\
    projeto que arrancou em 1994 e marca presença na Alemanha, Angola, Moçambique, Bélgica, Espanha, Gibraltar,\
    Holanda, Marrocos, Rússia, Cabo Verde e Brasil.`,
    logoImgPath: require('../../assets/images/partners/silver/2_CASAIS.svg').default,
    representatives: [
      {
        name: 'Ana Fernandes',
        imgPath: require('../../assets/images/partners/rep/Casais/Ana Fernandes.png'),
        cargo: 'Técnicas de Recrutamento e Seleção',
      },
      {
        name: 'Marisa Dias',
        imgPath: require('../../assets/images/partners/rep/Casais/Marisa Dias.png'),
        cargo: 'Técnicas de Recrutamento e Seleção',
      },
    ],
    interestedAreas: ['Engenharia Civil'],
    oportunities: [
      'Estágios curriculares, profissionais, estágio de verão',
      'Programa Arte & Engenho',
    ],
  },
  22: { // CIN
    description: `A CIN é uma empresa privada, sediada em Portugal, de tintas e vernizes que actua através das filiais em\
    Portugal, Espanha, França, Itália, Polónia, Angola, Moçambique, África do Sul e México e que procura alargar as suas\
    actividades a outros países na Europa, África e América do Sul. Líder do mercado português desde 1992 e líder do\
    mercado ibérico desde 1995, a CIN é a 10.a maior empresa de tintas na Europa* e a 36.a no mundo** com um volume de\
    negócios de 389 milhões de euros. A CIN é um excelente lugar para trabalhar que atrai, desenvolve e retém talentos\
    através da oferta de oportunidades de carreira estimulantes e gratificantes.\n
    Com oito Centros de Investigação e Desenvolvimento (I&D) em Portugal, Espanha, França e Itália, a CIN procura\
    continuamente a inovação para melhorar processos, antecipar as necessidades do mercado e garantir o êxito dos seus\
    produtos vendidos em mais de 50 países. Para além da inovação, há muito que a CIN está empenhada na qualidade e\
    sustentabilidade das suas operações, tendo obtido e mantido com êxito os seus Sistemas de Gestão certificados no\
    âmbito da Qualidade (ISO 9001), Ambiental (ISO 14001) e da Higiene, Saúde e Segurança (ISO 45001).`,
    logoImgPath: require('../../assets/images/partners/silver/3_CIN.svg').default,
    representatives: [
      { name: 'Fernanda Oliveira', imgPath: require('../../assets/images/partners/rep/CIN/Fernanda_Oliveira.jpg') },
    ],
    interestedAreas: [
      'Engenharia Química',
      'Engenharia e Gestão Industrial',
      'Engenharia Mecânica',
      'Engenharia Informática',
    ],
    oportunities: [
      'Estágios curriculares e extra-curriculares',
      `Estágios de verão para diversas áreas: Engenharia Química,\
      Engenharia e Gestão Industrial, Engenharia Mecânica - Gestão da Produção,\
      Engenharia Informática e Computação`,
    ],
  },
  23: { // Civil-Ria
    description: `A Civilria é uma empresa familiar de investimento, desenvolvimento e gestão de ativos em imobiliários\
    de capital 100% português. Com uma forte e consolidada presença no setor e uma distinta carteira de imóveis, a\
    Civilria está centrada na construção orientada por padrões de sustentabilidade, responsabilidade social e inovação,\
    acompanhando sempre as tendências do mercado imobiliário mundial. Visa construir e transformar o espaço urbano através\
    de soluções práticas e projetos de arquitetura marcantes. Realizar os sonhos e superar as espectativas dos clientes\
    com casas e espaços de trabalho confortáveis, acolhedores e inovadores é um dos seus maiores objetivos.`,
    logoImgPath: require('../../assets/images/partners/silver/4_CIVILRIA.svg').default,
    representatives: [
      { name: 'Maria Marques', imgPath: require('../../assets/images/partners/rep/Civilria/Maria Marques.png'), cargo: 'Direção de Obra' },
      { name: 'Micaela Miranda', imgPath: require('../../assets/images/partners/rep/Civilria/Micaela Miranda.png'), cargo: 'Direção Geral' },
    ],
    interestedAreas: [
      'Engenharia Civil', 'Engenharia do Ambiente',
    ],
    oportunities: ['Programa de estágios de verão, integradores do plano curricular e pós-licenciatura'],
  },
  24: { // MCOUTINHO
    description: `A inovação na oferta dos serviços e a criação de valor na relação com os Clientes são prioridade do Grupo\
    MCoutinho. Suportamos a nossa ambição na experiência de 67 anos no retalho automóvel. Localizados entre Bragança e Lisboa,\
    somos representantes de 24 marcas, com cerca de 1.300 colaboradores. A nossa missão: tornar a experiência do cliente única.`,
    logoImgPath: require('../../assets/images/partners/silver/5_MC_COUTINHO.png'),
    representatives: [
      { name: 'Marisa Maiato', imgPath: require('../../assets/images/partners/rep/MCOUTINHO/Marisa_Maiato.jpg'), cargo: 'Recursos Humanos' },
      { name: 'Catia Barbosa', imgPath: require('../../assets/images/partners/rep/MCOUTINHO/Catia_Barbosa.jpg'), cargo: 'Recursos Humanos' },
    ],
    interestedAreas: [
      'Engenharia mecânica',
      'Engenharia informática',
      'Engenharia eletrotécnica',
    ],
    oportunities: [
      'Programadores',
      'Full stack web developers',
      'Data engineer',
    ],
  },
  25: { // INEGI
    description: `O INEGI - Instituto de Ciência e Inovação em Engenharia Mecânica e Engenharia Industrial é um Centro de Tecnologia\
    e Inovação (CTI), criado em 1986, vocacionado para a realização de atividades de investigação e de inovação de base tecnológica,\
    transferência de tecnologia, consultoria e serviços tecnológicos, orientadas para o desenvolvimento da indústria e da economia em geral.`,
    logoImgPath: require('../../assets/images/partners/silver/6_INEGI.svg').default,
    representatives: [
      {
        name: 'Elisabete Barros',
        imgPath: require('../../assets/images/partners/rep/INEGI/Elisabete_Barros.jpg'),
        cargo: 'Especialista em Gestão de Pessoas',
      },
      {
        name: 'Catarina Costa',
        imgPath: require('../../assets/images/partners/rep/INEGI/Catarina_Costa.png'),
        cargo: 'Engenheiro de Desenvolvimento do Produto',
      },
    ],
    interestedAreas: ['Diversas áreas de engenharia'],
    oportunities: ['@INEGI Programa de Talento Jovem (programa de aceleração de talento)'],
  },
  26: { // PROEF
    description: `Na PROEF apresentamos soluções inovadoras à medida, respondendo aos desafios que nos são colocados nos principais setores\
    de atuação - Telecom, Power, Cities & Corporate.\n
    As nossas equipas multidisciplinares estão presentes em 11 países com soluções integradas\
    e em modelo chave na mão, que cobrem toda a cadeia de valor do cliente.\n
    Integrar a PROEF é ter a certeza de um caminho com aprendizagens contínuas, desafios constantes e oportunidades de crescimento infinitas.\n
    Fazer parte desta equipa permitirá partilhar outra visão do mundo e acompanhar uma equipa recheada de Know-how e com vontade de fomentar\
    o espírito de entre ajuda.\n
    Somos um grupo que ambiciona sempre mais, com a certeza que juntos alcançamos o sucesso.`,
    logoImgPath: require('../../assets/images/partners/op/71_PROEF.png'),
    representatives: [
      {
        name: 'Ana Bernardino',
        imgPath: require('../../assets/images/partners/rep/PROEF/Ana Bernardino.jpg'),
        cargo: 'Talent Acquisition & Recruitment Technician',
      },
      {
        name: 'Inês Maia',
        imgPath: require('../../assets/images/partners/rep/PROEF/Inês Maia.jpg'),
        cargo: 'Training Technician',
      },
    ],
    interestedAreas: [
      'Engenharia Electrotécnica e de Computadores',
      'Engenharia Civil',
      'Engenharia e Gestão Industrial',
      'Engenharia do Ambiente',
    ],
    oportunities: ['Mais direcionadas a estudantes de último ano da licenciatura ou do mestrado'],
  },
  27: { // PWC
    description: `As firmas da PwC colaboram com organizações e pessoas na criação do valor que procuram. A nossa network, constituída por firmas\
    independentes entre si, está presente em 151 países e integra cerca de 364.000 colaboradores, que partilham o objetivo de prestar serviços de\
    qualidade em auditoria, consultoria e fiscalidade.Os nossos escritórios, localizados em Portugal, Angola, Cabo Verde e Moçambique, contam com\
    mais de 60 partners, num total de mais de 2.200 colaboradores permanentes distribuídos por Lisboa, Porto, Coimbra, Funchal, Luanda, Cidade da\
    Praia e Maputo.\n
    António Brochado Correia, Territory Senior Partner, assume a liderança da Territory Leadership Team, da qual fazem também parte João Ramos,\
    Rosa Areias, Luís Boquinhas, Paulo Ribeiro, Ana Lopes e António Rodrigues. João Ramos é responsável pela área de Assurance e Rosa Areias pela\
    área de Tax & Legal Services. Ricardo Santos, em Angola, e João Veiga, em Moçambique, assumem a responsabilidade de Country Senior Partners.`,
    logoImgPath: require('../../assets/images/partners/silver/8_PWC.svg').default,
    representatives: [
      {
        name: 'Miguel Caeiro',
        imgPath: require('../../assets/images/partners/rep/PWC/Miguel Caeiro.png'),
        cargo: 'Senior Associate | Advisory (Business Transformation)',
      },
      {
        name: 'Francisca Queirós',
        imgPath: require('../../assets/images/partners/rep/PWC/Francisca Queiros.jpg'),
        cargo: 'Associate | Advisory (Business Transformation)',
      },
    ],
    interestedAreas: ['Finalistas de Licenciatura e Mestrado'],
    oportunities: ['Estágios profissionais', 'Programa de Trainees'],
  },
};

function usePartner(partIndex) {
  const getDescription = () => (partIndex !== '' && partners[partIndex].description ? partners[partIndex].description : '');
  const getLogoImgPath = () => (partIndex !== '' && partners[partIndex].logoImgPath ? partners[partIndex].logoImgPath : '');
  const getRepresentatives = () => (partIndex !== '' && partners[partIndex].representatives ? partners[partIndex].representatives : []);
  const getInterestedAreas = () => (partIndex !== '' && partners[partIndex].interestedAreas ? partners[partIndex].interestedAreas : []);
  const getOportunities = () => (partIndex !== '' && partners[partIndex].oportunities ? partners[partIndex].oportunities : []);

  return {
    getDescription, getLogoImgPath, getRepresentatives, getInterestedAreas, getOportunities,
  };
}

export default usePartner;
