/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  Route, Switch, Redirect,
} from 'react-router-dom';

import './globals.css';
// Já adicionei às dependências do projeto, mas continua a queixar-se
import ReactGA from 'react-ga4';
import Home from './pages/Home';
import Contacts from './pages/Contacts';
import Partners from './pages/Partners';
import Program from './pages/Program';
import Inscreve from './pages/Inscreve';
import Oportunidades from './pages/Oportunidades';
import Faq from './pages/Faq';

// Initialize React Ga for the Google Analytics
ReactGA.initialize('G-8HZL2EJB61');

function App() {
  document.title = 'ADATO';

  ReactGA.send({ hitType: 'pageview', page: '/' });

  return (
    <>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/contactos" component={Contacts} />
        <Route exact path="/partners" component={Partners} />
        <Route exact path="/program" component={Program} />
        <Route exact path="/empresas" component={Partners} />
        <Route exact path="/inscrição" component={Inscreve} />
        <Route exact path="/oportunidades" component={Oportunidades} />
        <Route exact path="/faq" component={Faq} />
        <Redirect to="/" />
      </Switch>
    </>
  );
}

export default App;
