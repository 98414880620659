// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-container {
    height: calc(100vh - 150px);
    text-align: center;
    padding-top: 100px;
}

.register-title > h2 {
    text-align: center;
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 2.7px;
    line-height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Inscreve/index.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,qBAAqB;IACrB,iBAAiB;AACrB","sourcesContent":[".register-container {\r\n    height: calc(100vh - 150px);\r\n    text-align: center;\r\n    padding-top: 100px;\r\n}\r\n\r\n.register-title > h2 {\r\n    text-align: center;\r\n    font-family: \"Poppins\";\r\n    font-size: 18px;\r\n    font-weight: 300;\r\n    letter-spacing: 2.7px;\r\n    line-height: 30px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
