/* eslint-disable max-len */
import React, { useEffect, useRef } from 'react';
import usePartner from '../../hooks/usePartner';

import './index.css';
import copo from '../../assets/images/copo.png';
import noPhoto from '../../assets/images/misc/no-photo-placeholder.png';

export default function PartnersInfo({ partnerOpen, setPartnerOpen, topPosition }) {
  const {
    getDescription, getLogoImgPath, getRepresentatives, getInterestedAreas, getOportunities,
  } = usePartner(partnerOpen);
  const divDescRefPc = useRef(null);
  const divDescRefMobile = useRef(null);

  useEffect(() => {
    if (partnerOpen) {
      const ref = window.innerWidth > 1024 || !getDescription() ? divDescRefPc : divDescRefMobile;
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        window.scrollTo({
          top: window.scrollY + rect.top - 100,
          behavior: 'smooth',
        });
      }
    }
  }, [partnerOpen]);

  const isPartnerSilver = () => ['ADVANTEK', 'CASAIS', 'CIN', 'CIVILRIA', 'MC_', 'INEGI', 'riOGkAAA', 'PWC'].some(
    (p) => getLogoImgPath().includes(p),
  );

  return (
    <>
      { getDescription() ? (
        <>
          <div className={`${partnerOpen ? 'partnersInfo-container partnersInfo-layout' : 'partnersInfo-hide'} ${isPartnerSilver() ? 'partnersInfo-silver-top' : ''} `}>
            {/* FIRST LINE */}
            <div>
              {/* DESCRIÇÂO */}
              <div className="divDesc" ref={divDescRefPc}>
                <p className="tituloDesc">Descrição</p>
                <p className="card-desc">
                  { getDescription() }
                </p>
              </div>

              {/* LOGOS IMAGENS */}
              <div className="divImagens">
                <img className="logoOport" src={getLogoImgPath() ? getLogoImgPath() : null} alt="" />
                {
                getRepresentatives().map((rep, index) => (
                  <>
                    {index === 0
                      ? <img className="representante1" src={rep.imgPath ? rep.imgPath : noPhoto} alt="" />
                      : <img className="representante" src={rep.imgPath ? rep.imgPath : noPhoto} alt="" />}
                    <p>{rep.name}</p>
                    <p>{rep.cargo}</p>
                  </>
                ))
              }
                <div style={{ marginBottom: 'auto' }} />
              </div>
            </div>

            <div className="horizontal-line-container">
              <hr className="line-left" />
              <img className="adatoLine" src={copo} alt="" />
              <hr className="line-right" />
            </div>

            {/* SECOND LINE */ }
            <div className="secondLine">
              {/* Áreas Procuradas */}
              <div className="divAreas">
                <p className="areas">Áreas Procuradas</p>
                <ul className="cursos">
                  {getInterestedAreas().map((area) => (
                    <li>{area}</li>
                  ))}
                </ul>
              </div>

              {/* Oportunidades */}
              <div className="divOportunidades">
                <p className="areas">{getOportunities().length ? 'Oportunidades' : ''}</p>
                <ul className="cursos">
                  {getOportunities().map((area) => (
                    <li>{area}</li>
                  ))}
                </ul>
              </div>
            </div>
            <button type="button" aria-label="Close Modal" className="partnersInfo-closeIcon" onClick={() => setPartnerOpen('')}>
              <svg width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" />
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>
          </div>
          <div className={`${partnerOpen ? 'modal-mobile' : 'partnersInfo-hide'}`} ref={divDescRefMobile}>
            <div className="logoMobile">
              <img className="logoOport" src={getLogoImgPath() ? getLogoImgPath() : null} alt="" />
            </div>
            <div className="divDesc">
              <p className="tituloDesc">Descrição</p>
              <p className="card-desc">
                { getDescription() }
              </p>
            </div>
            <div className="divImagensMobile">
              {
              getRepresentatives().map((rep) => (
                <>
                  <img className="representante" src={rep.imgPath ? rep.imgPath : noPhoto} alt="" />
                  <p className="nomeMobile">{rep.name}</p>
                  <p className="cargoMobile">{rep.cargo}</p>
                </>
              ))
            }
            </div>
            <div className="horizontal-line-container">
              <hr className="line-left" />
              <img className="adatoLine" src={copo} alt="" />
              <hr className="line-right" />
            </div>
            <div className="divAreas">
              <p className="areas">Áreas Procuradas</p>
              <ul className="cursos">
                {getInterestedAreas().map((area) => (
                  <li>{area}</li>
                ))}
              </ul>
            </div>
            <div className="divOportunidades">
              <p className="areas">{getOportunities().length ? 'Oportunidades' : ''}</p>
              <ul className="cursos">
                {getOportunities().map((area) => (
                  <li>{area}</li>
                ))}
              </ul>
            </div>
            <button type="button" aria-label="Close Modal" className="partnersInfo-closeIcon" onClick={() => setPartnerOpen('')}>
              <svg width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" />
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>
          </div>
        </>
      )
        : (
          <div
            className={`${partnerOpen ? 'partnersInfo-container-only-logo' : 'partnersInfo-hide'}`}
            ref={divDescRefPc}
            style={{ top: `${topPosition}px` }}
          >
            <img className="logoOport" src={getLogoImgPath() ? getLogoImgPath() : null} alt="" />
            <p className="card-desc">Informação brevemente disponível.</p>
          </div>
        )}
    </>
  );
}
