/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Navbar from '../../components/shared/Navbar';
import Footer from '../../components/shared/Footer';

import './index.css';

export default function Contacts() {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Navbar page={7} />
      <div className="contacts-absolute-up">
        <div className="contacts-container">
          <div className="contacts-map">
            <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1VsjCd-cVwKGA7BCIqpipe8TztyUC_T8&ehbc=2E312F&z=15" title="map" />
          </div>
          <div className="contacts-label">
            <div className="contacts-label-icon-column">
              <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5732 0.845929C11.9214 4.04593 13.2772 7.24342 14.6407 10.4384C14.9619 11.19 14.7569 11.6384 13.9521 11.9415C12.567 12.4626 11.1864 12.9912 9.79596 13.4973C9.53509 13.5917 9.48007 13.7127 9.50226 13.9549C9.86428 17.9808 13.0586 21.6217 17.2015 22.6906C17.8492 22.8576 18.5218 22.9411 19.1829 23.0605C19.4349 23.1081 19.5688 23.0388 19.6709 22.7891C20.2033 21.4797 20.7703 20.1795 21.324 18.8768C21.6469 18.1169 22.111 17.9257 22.9211 18.2288C26.3479 19.5165 29.775 20.8036 33.2024 22.0902C33.3844 22.1505 33.5505 22.2469 33.6894 22.3728C33.8284 22.4987 33.9371 22.6512 34.0081 22.82V23.7578C33.982 23.8474 33.9616 23.9383 33.9469 24.0301C33.4811 28.4092 29.4376 31.9198 24.7561 32C24.6168 32 24.4775 32 24.3382 32C22.6397 31.9994 20.9463 31.8258 19.2876 31.4823C10.3647 29.6367 3.36115 23.423 0.900625 15.1516C0.345166 13.2835 0 11.3854 0 9.4572C0 8.98956 0.0209998 8.52025 0.0629994 8.04927C0.382433 4.50104 3.14819 1.36868 6.79328 0.383299C7.43748 0.210438 8.10917 0.126096 8.76756 0H9.76047C10.1908 0.149478 10.4091 0.45595 10.5732 0.845929ZM2.25289 12.1562C3.18014 17.7177 6.03374 22.228 10.7205 25.701C13.9859 28.1228 17.7161 29.5248 21.8404 29.9833C23.1802 30.1319 24.5272 30.228 25.8759 30.0075C28.9726 29.5065 31.568 26.9436 31.9496 24.0184C31.9842 23.7495 31.9221 23.62 31.6372 23.5173C28.8324 22.476 26.0329 21.418 23.2326 20.3649L22.8927 20.238C22.8404 20.3608 22.804 20.4468 22.7667 20.5328C22.2343 21.7787 21.7046 23.0241 21.1776 24.2689C20.9664 24.7633 20.6452 24.9754 20.1225 24.9712C18.3754 24.957 16.6798 24.6664 15.1021 23.9582C10.2716 21.7871 7.71343 18.1754 7.47119 13.1307C7.44457 12.5812 7.6664 12.2764 8.20855 12.0727C8.93704 11.7979 9.66641 11.5265 10.3949 11.2526L12.4961 10.4585C12.4499 10.344 12.4162 10.258 12.3798 10.1737C11.3014 7.62227 10.2213 5.07335 9.13935 2.52693C8.86339 1.87975 8.8625 1.87975 8.13313 1.99916C4.96897 2.51524 2.378 5.1215 2.05325 8.11106C1.90507 9.46889 2.02929 10.8175 2.25289 12.1562Z" fill="#426D61" />
              </svg>

              <svg width="34" height="45" viewBox="0 0 34 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.1881 44.9368C16.702 44.9368 16.2159 44.6499 15.8336 44.0751C11.4327 37.4719 7.03373 30.8683 2.63674 24.2643C0.563708 21.1528 -0.322376 17.7698 0.104738 14.1297C0.562528 10.2346 2.35239 6.92967 5.42714 4.24463C7.64388 2.2997 10.3914 0.959183 13.37 0.369289C14.2372 0.191239 15.1304 0.119799 16.013 0H17.9008C18.3798 0.058251 18.8612 0.109908 19.339 0.176951C25.144 1.04852 29.4883 3.89402 32.0982 8.7926C34.8378 13.9374 34.5405 19.0942 31.4115 24.0346C27.1734 30.7279 22.8515 37.3752 18.5627 44.0377C18.1792 44.6367 17.6837 44.9368 17.1881 44.9368ZM5.05902 22.908C9.00766 28.8276 12.9528 34.7491 16.8943 40.6724C16.9757 40.7944 17.0654 40.9109 17.1704 41.0571C17.2683 40.9175 17.3462 40.812 17.4158 40.7032C21.2764 34.7 25.1357 28.6965 28.9939 22.6926C30.7637 19.9339 31.4657 16.9631 30.9006 13.7747C30.1372 9.46853 27.6748 6.21967 23.5641 4.17649C19.3284 2.07505 14.9393 2.02889 10.6386 4.038C6.56572 5.9394 4.03135 9.04319 3.10515 13.2032C2.32998 16.6708 3.05796 19.9075 5.05902 22.908Z" fill="#426D61" />
                <path d="M16.9805 7.89997C18.6573 7.90323 20.2954 8.36965 21.6876 9.24023C23.0798 10.1108 24.1636 11.3464 24.8019 12.7908C25.4402 14.2352 25.6043 15.8235 25.2735 17.3548C24.9427 18.8861 24.1318 20.2916 22.9434 21.3936C21.755 22.4955 20.2424 23.2444 18.5971 23.5455C16.9517 23.8467 15.2474 23.6865 13.6998 23.0853C12.1522 22.4841 10.8307 21.4688 9.90252 20.168C8.97435 18.8671 8.48118 17.339 8.48538 15.777C8.496 11.4302 12.3235 7.88238 16.9805 7.89997ZM11.2947 15.7781C11.2902 16.8224 11.6186 17.8445 12.2382 18.7148C12.8578 19.5851 13.7407 20.2645 14.7752 20.667C15.8096 21.0695 16.949 21.177 18.0491 20.9758C19.1492 20.7746 20.1604 20.2738 20.9547 19.5368C21.7489 18.7998 22.2906 17.8599 22.5109 16.8359C22.7313 15.812 22.6204 14.7502 22.1924 13.785C21.7645 12.8198 21.0386 11.9946 20.1068 11.414C19.1749 10.8334 18.0791 10.5235 16.958 10.5235C16.2158 10.522 15.4805 10.6568 14.7941 10.9201C14.1077 11.1834 13.4838 11.5701 12.9579 12.058C12.432 12.546 12.0144 13.1257 11.729 13.764C11.4436 14.4023 11.296 15.0867 11.2947 15.7781Z" fill="#426D61" />
              </svg>

              <svg width="47" height="32" viewBox="0 0 47 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.2141 17.6875C20.6537 18.1071 21.1775 18.434 21.7527 18.6478C23.451 19.2634 24.9387 18.813 26.2056 17.5873L41.2638 3.01198C41.3657 2.91396 41.4585 2.80824 41.6034 2.66177H4.69632C4.77897 2.75758 4.80954 2.80384 4.85483 2.84348C9.97006 7.79626 15.0898 12.7443 20.2141 17.6875ZM45.8536 2.2521C46.1255 2.8236 46.2687 3.445 46.2737 4.0747C46.2895 8.59726 46.2971 13.1202 46.2963 17.6435C46.2963 20.9634 46.2933 24.2834 46.2873 27.6034C46.2873 30.0052 44.4973 31.6704 41.985 31.6704C31.6013 31.6704 21.2187 31.6704 10.8373 31.6704C8.72691 31.6704 6.61651 31.6274 4.50838 31.6825C2.19531 31.743 0.378151 30.3609 0.041891 28.2322C0.0324116 28.2024 0.0182835 28.1741 0 28.1485L0 3.52407C0.266064 2.89524 0.437025 2.20254 0.81744 1.65191C1.58506 0.5198 2.76594 0.0352407 4.14268 0C4.21854 0 4.29439 0 4.36912 0H41.9363C43.7025 0 45.0804 0.672877 45.8536 2.2521ZM43.5474 27.1783V4.5053L32.093 15.8231L43.5474 27.1783ZM4.94767 28.9921H41.3521L29.9351 17.66C29.2761 18.313 28.6704 18.9209 28.0568 19.5211C26.8114 20.7446 25.3112 21.455 23.528 21.5453C21.4538 21.6554 19.6967 20.9451 18.235 19.5233C17.6191 18.9242 17.0145 18.3119 16.3579 17.6633L4.94767 28.9921ZM2.7399 27.1617L14.1376 15.8583L2.7399 4.5934V27.1617Z" fill="#426D61" />
              </svg>

            </div>
            <div className="contacts-label-info-column">
              <p>+351 225 081 554</p>
              {viewportWidth > 1280 ? (
                <p>
                  Rua Dr. Roberto Frias s/n, sala B(-109),
                  <br />
                  4200-465 Porto
                </p>
              ) : (
                <p>
                  Rua Dr. Roberto
                  <br />
                  Frias s/n, sala B(-109), 4200-465 Porto
                </p>
              )}
              <p>adato@junifeup.pt</p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
