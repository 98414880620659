/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';

import './index.css';
import Navbar from '../../components/shared/Navbar';
import Footer from '../../components/shared/Footer';
import PartnersInfo from '../../components/PartnersInfo';

import M_Diamond from '../../assets/images/partners/titles/mobile/DIAMOND.svg';
import M_Gold from '../../assets/images/partners/titles/mobile/GOLD.svg';
import M_Silver from '../../assets/images/partners/titles/mobile/SILVER.svg';
import Diamond from '../../assets/images/partners/titles/desktop/DIAMOND.svg';
import Gold from '../../assets/images/partners/titles/desktop/GOLD.svg';
import Silver from '../../assets/images/partners/titles/desktop/SILVER.svg';

import CardBorder from '../../assets/images/cardBorder.svg';

function OportunidadesCard({
  CompanyLogo, index, i, setPartnerOpen, setTopPosition,
}) {
  const divRef = useRef(null);

  const handleClick = () => {
    if (divRef.current) {
      const rect = divRef.current.getBoundingClientRect();
      setTopPosition(rect.top + window.scrollY);
    }
    setPartnerOpen(`${index}${i}`);
  };

  return (
    <div className="o-oportunidades-container" ref={divRef} onClick={() => handleClick()} onKeyDown={() => setPartnerOpen(`${index}${i}`)} role="button" tabIndex={-1}>
      <div
        className="o-background-card"
        style={{
          backgroundColor: index === 0 ? '#89B8C2B2' : index === 1 ? '#C5AE71' : '#918F8F',
        }}
      />
      <div className="o-oportunidades-card">
        <img
          src={CardBorder}
          alt="Card Border"
          className="o-card-border-image"
        />
        <div className="o-oportunidades-card-content">
          <img
            src={CompanyLogo}
            alt="Company Logo"
            className={
              index === 1 && (i === 0 || i === 2 || i === 5 || i === 6 || i === 7 || i === 9)
                ? 'special-logo'
                : (index === 0 && i === 2) || (index === 1 && i === 4)
                  ? 'max-special-logo'
                  : (index === 1 && i === 3)
                    ? 'ea-logo'
                    : 'o-company-logo'
            }
          />
        </div>
      </div>
    </div>
  );
}
export default function Oportunidades() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [partnerOpen, setPartnerOpen] = useState('');
  const [topPosition, setTopPosition] = useState(0);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = windowWidth <= 820;
  const titles = isMobile ? [M_Diamond, M_Gold, M_Silver] : [Diamond, Gold, Silver];

  const diamondPartnersContext = require.context('../../assets/images/partners/diamond', false, /\.(png|jpe?g|svg)$/);
  let diamondPartners = diamondPartnersContext.keys().map(diamondPartnersContext);

  diamondPartners = diamondPartners.slice(0, diamondPartners.length - 3);

  const goldPartnersContext = require.context('../../assets/images/partners/gold', false, /\.(png|jpe?g|svg)$/);
  let goldPartners = goldPartnersContext.keys().map(goldPartnersContext);

  goldPartners = goldPartners.slice(0, goldPartners.length - 2);

  const silverPartnersContext = require.context('../../assets/images/partners/silver', false, /\.(png|jpe?g|svg)$/);
  let silverPartners = silverPartnersContext.keys().map(silverPartnersContext);

  silverPartners = silverPartners.slice(0, silverPartners.length - 1);

  const partners = [diamondPartners, goldPartners, silverPartners];

  return (
    <>
      <Navbar page={2} />
      <PartnersInfo partnerOpen={partnerOpen} setPartnerOpen={setPartnerOpen} topPosition={topPosition} />
      <div
        className={`partnersInfo-dim ${partnerOpen ? 'partnersInfo-dim-enabled' : 'partnersInfo-dim-disabled'}`}
        onClick={() => setPartnerOpen('')}
        onKeyDown={() => setPartnerOpen('')}
        role="button"
        tabIndex={-1}
        aria-label="close"
      />
      <div className="o-empresas-page">
        <h1 className="titulo" style={{ marginTop: '180px' }}>OPORTUNIDADES</h1>
        <h2 className="subtitulo">Sabe mais sobre as empresas do ADATO’24</h2>
        <div className="o-empresas-with-titles parent">
          {titles.map((title, index) => (
            <React.Fragment key={index}>
              <img src={title} alt="" className="o-partner-title" />
              <div className={`o-grid-container o-title-${index}`}>
                {partners[index].map((partner, i) => (
                  <OportunidadesCard key={i} CompanyLogo={partner} index={index} i={i} setPartnerOpen={setPartnerOpen} setTopPosition={setTopPosition} />
                ))}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}
