import React from 'react';
import Navbar from '../../components/shared/Navbar';
import Footer from '../../components/shared/Footer';

import './index.css';

export default function Inscreve() {
  return (
    <>
      <Navbar page={6} />
      <div className="register-container">
        <div className="register-title">
          <h1>INSCRIÇÃO</h1>
          <h2>Inscrições encerradas.</h2>
        </div>
      </div>
      <Footer />
    </>
  );
}
