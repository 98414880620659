/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import ReactGA from 'react-ga4';
import React, { useState, useEffect } from 'react';
import copo from '../../assets/images/copo.png';
import deloitte from '../../assets/images/partners/diamond/1_DELOITTE.svg';
import geg from '../../assets/images/partners/diamond/2_G.svg';
import itsector from '../../assets/images/partners/diamond/3_IT_SECTOR.png';
import m_itsector from '../../assets/images/empresas/itsector.png';
import keo from '../../assets/images/partners/diamond/4_KEO.svg';
import outsystems from '../../assets/images/partners/diamond/5_OUT_SYSTEMS.svg';
import mcsonae from '../../assets/images/partners/diamond/6_MC_SONAE.svg';
// import grupo from '../../assets/images/grupo.png';
import Navbar from '../../components/shared/Navbar';
import Footer from '../../components/shared/Footer';
import Carousel from '../../components/Carousel';
import logo from '../../assets/images/logo.png';
import homepageMain from '../../assets/images/homepage img.png';
import ImageCarousel from '../../components/ImageCarousel';

import './index.css';

export default function Home() {
  const handleButtonClick = () => {
    ReactGA.event({
      category: 'User',
      action: 'Botão Inscrever clicado',
    });
  };
  document.title = 'ADATO';

  const [isDesktop, setisDesktop] = useState(window.innerWidth > 900);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setisDesktop(window.innerWidth > 900);
    });
  }, []);

  const partnersLinks = [
    'https://www2.deloitte.com/pt/pt.html',
    'https://geg.pt/',
    'https://www.itsector.pt/',
    'https://www.keoic.com/',
    'https://www.outsystems.com/pt-br/',
    'https://mc.sonae.pt/',
  ];

  return (
    <>
      <Navbar page={1} />
      <div className="page">
        <div className="homepage-main-img-relative">
          <div className="homepage-main-image">
            <img src={homepageMain} alt="" />
          </div>
        </div>
        <div className="title">
          <p>ANOTHER DAY AT THE OFFICE</p>
        </div>
        <div className="subtitle">
          <p>O evento que promete revolucionar as feiras de emprego</p>
        </div>
        <div className="botoes">
          <a href="inscrição">
            <button type="button" className="inscreve" onClick={handleButtonClick}>INSCREVE-TE</button>
          </a>
          <a href="program" className="sabeMais">SABE MAIS</a>
        </div>
        <div className="adato">
          <div className="content">
            <p className="pergunta">O QUE É O ADATO?</p>
            <p className="texto">
              O ADATO é um evento que promove o networking entre estudantes e empresas num contexto mais informal e
              direto, distinguindo-se de uma tradicional feira de emprego. As relações criadas neste evento, serão
              enriquecedoras para ambos os públicos-alvo: estudantes e empresas convidadas.
            </p>
          </div>
          <div className="image-container">
            <img alt="" src={copo} />
          </div>
        </div>
      </div>
      <div className="box">
        <div className="caixa">
          <p className="gravata">SEM GRAVATAS</p>
          <div className="mytextdiv">
            <div className="divider" />
            <div className="banca">
              SEM BANCAS
            </div>
            <div className="divider" />
          </div>
          <p className="complicacoes">SEM COMPLICAÇÕES</p>
        </div>
      </div>
      <div className="parte2">
        <div className="box2">
          <a href="www.junifeup.pt">
            <img className="logo" alt="Logo" src={logo} />
          </a>
          <div className="quemSomos">
            <p className="quem">QUEM SOMOS?</p>
            <p className="descricao">
              A JuniFEUP é a Júnior Empresa da FEUP, constituída por 63 estudantes das mais variadas áreas da engenharia.
              Graças ao nosso espírito empreendedor, aceleramos a aprendizagem dos nossos colaboradores
              e efetivamos a ligação entre o meio académico e empresarial.
            </p>
          </div>
        </div>
        <div className="box2mobile">
          <div className="quemSomos">
            <p className="quem">QUEM SOMOS?</p>
            <p className="descricao">
              A JuniFEUP é a Júnior Empresa da FEUP, constituída por 63 estudantes das mais variadas áreas da engenharia.
              Graças ao nosso espírito empreendedor, aceleramos a aprendizagem dos nossos colaboradores
              e efetivamos a ligação entre o meio académico e empresarial.
            </p>
          </div>
          <a href="www.junifeup.pt">
            <img className="logo" alt="Logo" src={logo} />
          </a>
        </div>
        <div className="empresasMain">
          <p className="tituloEmpresas">EMPRESAS</p>
          {isDesktop ? (
            <div className="empresasMain">
              <div className="images">
                <div className="line1">
                  <a href={partnersLinks[0]} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img alt="Deloitte" src={deloitte} />
                  </a>
                  <a href={partnersLinks[1]} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img alt="GEG" src={geg} />
                  </a>
                  <a href={partnersLinks[2]} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img alt="ITSector" src={itsector} />
                  </a>
                  <a href={partnersLinks[3]} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img alt="KEO" src={keo} />
                  </a>
                </div>
              </div>
              <div className="images">
                <div className="line2">
                  <a href={partnersLinks[4]} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img alt="OutSystems" src={outsystems} style={{ marginRight: 0, paddingRight: '70px' }} />
                  </a>
                  <a href={partnersLinks[5]} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img alt="MCSonae" src={mcsonae} />
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div className="mobileEmpresas">
              <a href={partnersLinks[0]} target="_blank" rel="noopener noreferrer">
                <img alt="Deloitte" src={deloitte} style={{ marginTop: '40px' }} />
              </a>
              <a href={partnersLinks[1]} target="_blank" rel="noopener noreferrer">
                <img alt="GEG" src={geg} />
              </a>
              <a href={partnersLinks[2]} target="_blank" rel="noopener noreferrer">
                <img alt="ITSector" src={m_itsector} />
              </a>
              <a href={partnersLinks[3]} target="_blank" rel="noopener noreferrer">
                <img alt="KEO" src={keo} />
              </a>
              <a href={partnersLinks[4]} target="_blank" rel="noopener noreferrer">
                <img alt="OutSystems" src={outsystems} />
              </a>
              <a href={partnersLinks[5]} target="_blank" rel="noopener noreferrer">
                <img alt="MCSonae" src={mcsonae} />
              </a>
            </div>
          )}
          <a href="empresas" className="verEmpresas">Ver todas as empresas</a>
        </div>
      </div>
      <Carousel />
      <ImageCarousel />
      <Footer />
    </>
  );
}
