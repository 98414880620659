import React from 'react';
import logo from '../../../assets/images/logo/juni-logo-white.svg';
import facebook from '../../../assets/images/contacts/facebook.svg';
import instagram from '../../../assets/images/contacts/instagram.svg';
import linkedin from '../../../assets/images/contacts/linkedin.svg';

import './index.css';

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-container-pc">
        <div className="footer-column">
          <ul>
            <li className="footer-montserrat">
              <a href="empresas">Empresas</a>
            </li>
            <li className="footer-montserrat pt-20">
              <a href="oportunidades">Oportunidades</a>
            </li>
            <li className="footer-montserrat pt-20">
              <a href="program">Programa</a>
            </li>
            <li className="footer-montserrat pt-20">
              <a href="faq">FAQ</a>
            </li>
            <li className="footer-montserrat pt-20">
              <a href="inscrição">Inscreve-te</a>
            </li>
            <li className="footer-montserrat pt-20">
              <a href="contactos">Contactos</a>
            </li>
          </ul>
        </div>
        <div className="footer-column footer-logo">
          <div>
            <a href="https://www.junifeup.pt" target="_blank" rel="noreferrer">
              <img src={logo} alt="junifeup logo" />
            </a>
          </div>
          <div className="social-media">
            <a href="https://www.facebook.com/junifeup" target="_blank" rel="noreferrer" className="social-icon">
              <img src={facebook} alt="facebook" />
            </a>
            <a href="https://www.instagram.com/junifeup" target="_blank" rel="noreferrer" className="social-icon">
              <img src={instagram} alt="instagram" />
            </a>
            <a href="https://pt.linkedin.com/company/junifeup" target="_blank" rel="noreferrer" className="social-icon">
              <img src={linkedin} alt="linkedin" />
            </a>
          </div>
        </div>

        <div className="footer-column footer-contact">
          <p className="footer-montserrat-strong">
            <span className="footer-phone-prefix">
              {'+351 '}
            </span>
            225 081 554
          </p>
          <p className="footer-montserrat-strong">junifeup@junifeup.pt</p>
          <address className="footer-montserrat">
            Rua Dr. Roberto Frias s/n, sala B(-109),
            <br />
            4200-465 Porto
          </address>
          <p className="footer-montserrat">&copy; 2024 JuniFEUP - All Rights Reserved</p>
        </div>
      </div>
      <div className="footer-container-mobile">
        <div className="footer-content-mobile">
          <div className="footer-logo">
            <a href="https://www.junifeup.pt" target="_blank" rel="noreferrer">
              <img src={logo} alt="junifeup logo" />
            </a>
          </div>
          <p className="footer-montserrat-strong">
            <span className="footer-phone-prefix">
              {'+351 '}
            </span>
            225 081 554
          </p>
          <p className="footer-montserrat-strong">junifeup@junifeup.pt</p>
          <address className="footer-montserrat">
            <p>Rua Dr. Roberto Frias s/n,</p>
            <p>sala B(-109), 4200-465 Porto</p>
          </address>
          <div className="social-media">
            <a href="https://www.facebook.com/junifeup" target="_blank" rel="noreferrer" className="social-icon">
              <img src={facebook} alt="facebook" />
            </a>
            <a href="https://www.instagram.com/junifeup" target="_blank" rel="noreferrer" className="social-icon">
              <img src={instagram} alt="instagram" />
            </a>
            <a href="https://pt.linkedin.com/company/junifeup" target="_blank" rel="noreferrer" className="social-icon">
              <img src={linkedin} alt="linkedin" />
            </a>
          </div>
          <p className="footer-montserrat">&copy; 2024 JuniFEUP - All Rights Reserved</p>
        </div>
      </div>
    </div>
  );
}
