/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import './index.css';

export default function ImageCarousel() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 820);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 820);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Import images
  const imagesContext = require.context('../../assets/images/media', false, /\.(png|jpe?g|svg)$/);
  const images = imagesContext.keys().map(imagesContext);

  // slice into 2 arrays firstHalf and secondHalf with odd and even indexes
  const firstHalf = images.filter((image, index) => index % 2 === 0);
  const secondHalf = images.filter((image, index) => index % 2 !== 0);

  return (
    <div className="slider-container">
      <div className="slider-title-font">
        <p>ADATO’23</p>
      </div>
      <div className="body3">
        <div className="slider3">
          <div className="slider-tracker3">
            {firstHalf.map((image, index) => (
              <div className="slide3">
                <img key={index} src={image} alt="Empresa" />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div>
        <div className="slider3" style={isMobile ? { marginLeft: '0px' } : {}}>
          <div className="slider-tracker3">
            {secondHalf.map((image, index) => (
              <div className="slide3">
                <img key={index} src={image} alt="media" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
