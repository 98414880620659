/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';

import './index.css';
import Navbar from '../../components/shared/Navbar';
import Footer from '../../components/shared/Footer';

import M_Diamond from '../../assets/images/partners/titles/mobile/DIAMOND.svg';
import M_Gold from '../../assets/images/partners/titles/mobile/GOLD.svg';
import M_Silver from '../../assets/images/partners/titles/mobile/SILVER.svg';
import Diamond from '../../assets/images/partners/titles/desktop/DIAMOND.svg';
import Gold from '../../assets/images/partners/titles/desktop/GOLD.svg';
import Silver from '../../assets/images/partners/titles/desktop/SILVER.svg';

export default function Partners() {
  document.title = 'ADATO';

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const diamondPartnersContext = require.context('../../assets/images/partners/diamond', false, /\.(png|jpe?g|svg)$/);
  let diamondPartners = diamondPartnersContext.keys().map(diamondPartnersContext); // Original for desktop

  // For desktop screens
  if (windowWidth > 1147) {
    diamondPartners = diamondPartners.slice(0, diamondPartners.length - 1);
  }

  // For tablet screens
  if (windowWidth > 820 && windowWidth <= 1147) {
    diamondPartners = diamondPartners.slice(0, diamondPartners.length - 3);
  }

  // For Mobile screens
  if (windowWidth <= 820) {
    diamondPartners = diamondPartners.slice(0, diamondPartners.length - 3);
  }

  const goldPartnersContext = require.context('../../assets/images/partners/gold', false, /\.(png|jpe?g|svg)$/);
  const goldPartners = goldPartnersContext.keys().map(goldPartnersContext); // Original for desktop

  const silverPartnersContext = require.context('../../assets/images/partners/silver', false, /\.(png|jpe?g|svg)$/);
  let silverPartners = silverPartnersContext.keys().map(silverPartnersContext); // Original for desktop

  // For desktop screens
  if (windowWidth > 1147) {
    silverPartners = silverPartners.slice(0, silverPartners.length - 1);
  }

  // For mobile screens
  if (windowWidth <= 820) {
    silverPartners = silverPartners.slice(0, silverPartners.length - 1);
  }

  const partners = [diamondPartners, goldPartners, silverPartners];

  const partnersLinks = [
    [
      'https://www2.deloitte.com/pt/pt.html',
      'https://geg.pt/',
      'https://www.itsector.pt/',
      'https://www.keoic.com/',
      'https://www.outsystems.com/pt-br/',
      'https://mc.sonae.pt/',
    ],
    [
      'https://www.accenture.com/pt-pt',
      'https://www.deus.ai/',
      'https://pt.dsr-corporation.com/',
      'https://www.ey.com/pt_pt',
      'https://www.glinttglobal.com/pt/',
      'https://www.lactogal.pt/homepage.aspx',
      'https://www.nos.pt/',
      'https://www.siemens.com/pt/pt.html',
      'https://www.sisint.pt/',
      'https://www.veolia.pt/',
    ],
    [
      'https://advantekgroup.com/',
      'https://casais.pt/',
      'https://cin.com/pt',
      'https://www.civilria.pt/',
      'https://www.mcoutinho.pt/',
      'https://www.inegi.pt/pt/',
      'https://proef.com/pt/',
      'https://www.pwc.pt/',
    ],
  ];

  const titles = windowWidth <= 820 ? [M_Diamond, M_Gold, M_Silver] : [Diamond, Gold, Silver];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 820);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth <= 820);
    });
  }, []);

  return (
    <body style={isMobile ? {
      backgroundColor: '#f2f0ed', margin: 0, overflow: 'hidden', overflowX: 'hidden',
    } : {}}
    >
      <Navbar page={2} />
      <div className="page" style={isMobile ? { overflow: 'hidden', backgroundColor: '#f2f0ed', overflowX: 'hidden' } : {}}>
        <p className="_titulo">EMPRESAS</p>
        <p className="_subtitulo">Conhece as empresas desta edição</p>
        <div className="_empresas-with-titles parent">
          {titles.map((title, index) => (
            <div key={index} className={`title-${index}`}>
              {index === 1 && <div className="rectangle" />}
              {index === 1 && <div className="triangle-top" />}
              {index === 1 && <div className="triangle-bottom" />}
              <img src={title} alt="" className={`partner-title-${index}`} />
              <div className="_empresas">
                {partners[index].map((partner, partnerIndex) => (
                  <div key={partnerIndex} className={`partner-${partnerIndex}`}>
                    <a href={partnersLinks[index][partnerIndex]} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img src={partner} alt="Empresa" className={partnerIndex} />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </body>
  );
}
