import React from 'react';

import './index.css';

export default function Carousel({ className, author, comment }) {
  const cardClass = `card ${className}`;

  return (
    <div className={cardClass}>
      <div className="card-author-outer">
        <div className="card-author-inner">
          <p className="card-author-text">
            {author}
          </p>
        </div>
      </div>
      <div className="card-comment">
        <p className="card-comment-text">
          {comment}
        </p>
      </div>
    </div>
  );
}
